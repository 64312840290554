@import 'react-nice-dates/src/style.scss';
$nice-dates-color-gray-dark: #333;
$nice-dates-color-gray: #999;
$nice-dates-color-gray-light: #ddd;
$nice-dates-color-accent: $nice-dates-color-gray-dark;
$nice-dates-font-size-small: 12px;
$nice-dates-font-size-base: 14px;
$nice-dates-font-size-big: 16px;

.nice-dates-navigation{
    align-items:center;
    color:$blue;
    display:flex;
    justify-content:space-between;
    position:relative;
    text-align:center;
    text-transform:capitalize
}
.nice-dates-navigation_current{
    flex-grow:1;
    font-size:13px
}
.nice-dates-navigation_next,.nice-dates-navigation_previous{
    cursor:pointer;
    height:0;
    padding-bottom:14.28571%;
    position:relative;
    width:14.28571%
}
.nice-dates-navigation_next:before,.nice-dates-navigation_previous:before{
    border-right:2px solid #818d98;
    border-top:2px solid #818d98;
    box-sizing:border-box;
    content:"";
    display:block;
    height:10px;
    left:50%;
    margin-left:-4px;
    margin-top:-4px;
    position:absolute;
    top:50%;
    transform:rotate(45deg);
    transition:border-color .2s;
    width:8px
}
.nice-dates-navigation_next:hover:before,.nice-dates-navigation_previous:hover:before{
    border-color:#438cd2
}
.nice-dates-navigation_next.-disabled,.nice-dates-navigation_previous.-disabled{
    cursor:default;
    pointer-events:none
}
.nice-dates-navigation_next.-disabled:before,.nice-dates-navigation_previous.-disabled:before{
    border-color:#d3dde6
}
.nice-dates-navigation_previous:before{
    transform:rotate(-135deg)
}
.nice-dates-week-header{
    box-shadow:0 1px 0 rgba(0,0,0,.06);
    display:flex;
    padding:8px 0
}
.nice-dates-week-header_day{
    color:#818d98;
    font-size:10px;
    line-height:1;
    text-align:center;
    text-transform:capitalize;
    width:14.28571%
}
.nice-dates-grid{
    box-sizing:content-box;
    overflow:hidden;
    position:relative;
    user-select:none
}
.nice-dates-grid_container{
    backface-visibility:hidden;
    display:flex;
    flex-wrap:wrap;
    left:0;
    position:absolute;
    right:0;
    transition-timing-function:cubic-bezier(.4,0,.2,1);
    transition-property:none;
    will-change:transform
}
.nice-dates-grid_container.-moving .nice-dates-day_date{
    transition:color .3s
}
.nice-dates-grid_container.-origin-bottom{
    top:auto;
    bottom:0
}
.nice-dates-grid_container.-origin-top{
    top:0;
    bottom:auto
}
.nice-dates-grid_container.-transition{
    transition-property:transform
}
.nice-dates-day{
    border-top:1px solid transparent;
    box-sizing:border-box;
    color:#0e3254;
    cursor:pointer;
    font-size:13px;
    position:relative;
    text-align:center;
    width:14.28571%;
    align-items:center;
    display:flex;
    justify-content:center;
    flex-direction:column
}
.nice-dates-day_date{
    transition:color .15s;
    will-change:color;
    z-index:3
}
.nice-dates-day_month{
    color:#818d98;
    font-size:8px;
    left:0;
    line-height:1;
    position:absolute;
    right:0;
    text-transform:capitalize;
    top:10%;
    z-index:3
}
.nice-dates-day:after,.nice-dates-day:before{
    border-radius:999px;
    bottom:0;
    box-sizing:border-box;
    content:"";
    display:block;
    left:0;
    opacity:0;
    position:absolute;
    right:0;
    top:0
}
.nice-dates-day:before{
    background-color:#438cd2;
    z-index:1
}
.nice-dates-day:after{
    border:2px solid #438cd2;
    transform:scale(.95);
    transition-duration:.15s;
    transition-property:transform,opacity;
    z-index:2
}
.nice-dates-day:not(.-disabled):hover:after{
    opacity:1;
    transform:scale(1)
}
@media (hover:none){
    .nice-dates-day:after{
        content:none
    }
    .nice-dates-day.-selected *{
        color:#fff
    }
}
.nice-dates-day.-selected:before{
    opacity:1
}
.nice-dates-day.-selected:hover:after{
    background-color:#fff
}
.nice-dates-day.-selected.-disabled *,.nice-dates-day.-selected:not(:hover) *{
    color:#fff
}
.nice-dates-day.-selected.-selected-start:before{
    border-top-right-radius:0;
    border-bottom-right-radius:0;
    right:0
}
.nice-dates-day.-selected.-selected-end:before{
    border-top-left-radius:0;
    border-bottom-left-radius:0;
    left:0
}
.nice-dates-day.-selected.-selected-middle:before{
    border-radius:0;
    left:0;
    right:0
}
.nice-dates-day.-selected.-selected-middle:nth-child(7n+1):before{
    border-top-left-radius:2px;
    border-bottom-left-radius:2px
}
.nice-dates-day.-selected.-selected-middle:not(.-end):nth-child(7n):before{
    border-top-right-radius:2px;
    border-bottom-right-radius:2px
}
.nice-dates-day.-today{
    font-weight:600
}
.nice-dates-day.-wide:after,.nice-dates-day.-wide:before{
    left:12.5%;
    right:12.5%
}
.nice-dates-day.-outside{
    color:#818d98
}
.nice-dates-day.-disabled{
    cursor:default;
    pointer-events:none;
    color:#d3dde6
}
.nice-dates-popover{
    background-color:#fff;
    border-radius:8px;
    box-shadow:0 1px 8px rgba(0,0,0,.12);
    margin:8px 0;
    max-width:600px;
    position:absolute;
    transform-origin:top;
    transform:perspective(1000px);
    transition-property:transform,opacity;
    transition-duration:.4s;
    transition-timing-function:cubic-bezier(.4,.2,0,1.1);
    width:100%;
    will-change:transform,opacity;
    z-index:9
}
.nice-dates-popover:not(.-open){
    opacity:0;
    pointer-events:none;
    transform:perspective(1000px) rotateX(-15deg)
}
*{
    box-sizing:border-box
}
html{
    scroll-behavior:smooth
}
body{
    -moz-osx-font-smoothing:grayscale;
    -webkit-font-smoothing:antialiased;
    color:#0e3254;
    font-family:IBM Plex Sans,sans-serif;
    font-size:17px;
    line-height:1.75;
    margin:0;
    padding:0 16px
}
h1,h2,h3{
    font-family:Poppins,sans-serif;
    line-height:1.25
}
h1+p,h2+p,h3+p{
    margin-top:1em
}
h1{
    font-size:40px;
    margin:0
}
h1+p{
    margin-top:.5em
}
h2{
    font-size:32px
}
h2,h3{
    margin:3em 0 0
}
h3{
    font-size:24px
}
p{
    margin:1.5em 0 0
}
a{
    color:#438cd2;
    text-decoration:none
}
a:hover{
    text-decoration:underline
}
code,pre{
    background-color:#f6f8fa;
    font-family:IBM Plex Mono,sans-serif
}
code{
    padding:1px 4px;
    white-space:pre-wrap
}
pre{
    font-size:14px;
    line-height:1.5;
    margin:2em 0;
    overflow:auto;
    padding:16px;
    white-space:pre
}
pre code{
    padding:0;
    white-space:inherit
}
nav{
    display:flex;
    flex-wrap:wrap;
    margin-top:12px
}
nav a{
    margin-right:16px;
    white-space:nowrap
}
footer{
    color:#818d98;
    font-size:14px;
    margin-top:120px;
    text-align:center
}
.container{
    max-width:600px;
    margin:0 auto;
    padding:180px 0;
    position:relative
}
.tagline{
    color:#818d98;
    font-size:21px
}
.sponsor{
    color:#818d98;
    font-size:14px
}
.input{
    -webkit-appearance:none;
    background-color:#fff;
    border:none;
    color:#0e3254;
    display:block;
    font-family:inherit;
    font-size:13px;
    height:38px;
    outline:none;
}
.input.-focused,.input:focus{
    border-color:#438cd2
}
.input::placeholder{
    color:#d3dde6
}
.date-range{
    display:flex;
    justify-content:space-between;
}
.date-range .input{
    width:100%;
}
.token-line{
    min-height:1.5em;
}
.token.plain{
    color:#0e3254;
}
.nice-dates-day.-highlight{
    color:orange;
}
