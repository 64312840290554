//Font Family
@font-face {
  font-family: 'HKGrotesk-Light';
  src: url('../fonts/HKGrotesk-Light.eot');
  src: url('../fonts/HKGrotesk-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HKGrotesk-Light.woff2') format('woff2'),
  url('../fonts/HKGrotesk-Light.woff') format('woff'),
  url('../fonts/HKGrotesk-Light.ttf') format('truetype'),
  url('../fonts/HKGrotesk-Light.svg#HKGrotesk-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'HKGrotesk-Regular';
  src: url('../fonts/HKGrotesk-Regular.eot');
  src: url('../fonts/HKGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HKGrotesk-Regular.woff2') format('woff2'),
  url('../fonts/HKGrotesk-Regular.woff') format('woff'),
  url('../fonts/HKGrotesk-Regular.ttf') format('truetype'),
  url('../fonts/HKGrotesk-Regular.svg#HKGrotesk-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HKGrotesk-Medium';
  src: url('../fonts/HKGrotesk-Medium.eot');
  src: url('../fonts/HKGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HKGrotesk-Medium.woff2') format('woff2'),
  url('../fonts/HKGrotesk-Medium.woff') format('woff'),
  url('../fonts/HKGrotesk-Medium.ttf') format('truetype'),
  url('../fonts/HKGrotesk-Medium.svg#HKGrotesk-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'HKGrotesk-Bold';
  src: url('../fonts/HKGrotesk-Bold.eot');
  src: url('../fonts/HKGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HKGrotesk-Bold.woff2') format('woff2'),
  url('../fonts/HKGrotesk-Bold.woff') format('woff'),
  url('../fonts/HKGrotesk-Bold.ttf') format('truetype'),
  url('../fonts/HKGrotesk-Bold.svg#HKGrotesk-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}



