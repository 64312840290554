.infinite-dropdown {
  input {

  }
  .results-box {
    position: absolute;
    ul.results {
      list-style: none;
      li {
        padding: 5px 10px;
      }
    }
  }
}