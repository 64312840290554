.chat-box {
  position: fixed;
  top: 0;
  right: 0;
  width: 330px;
  background: #fff;
  height: 100vh;
  box-shadow: 0 3px 24px rgba(0, 0, 0, .16);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all ease-in-out;
}
.chat-box.open {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 991px) {
  .chat-box {
    top: 53px;
  }
}
.chat-box .chat-box-header {
  padding: 20px;
  background: #f0f0f0;
}
.chat-box .chat-box-header h2 {
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 10px;
  position: relative;
  padding-right: 25px;
}
.chat-box .chat-box-header h2 .close-button {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 5px;
  cursor: pointer;
}
.chat-box .chat-box-header h2 .close-button:before, .chat-box .chat-box-header h2 .close-button:after {
  position: absolute;
  right: 10px;
  content: ' ';
  height: 20px;
  width: 2px;
  background-color: #ff4628;
}
.chat-box .chat-box-header h2 .close-button:before {
  transform: rotate(45deg);
}
.chat-box .chat-box-header h2 .close-button:after {
  transform: rotate(-45deg);
}
.chat-box .chat-box-header h2 .close-button:hover {
  opacity: 0.6;
}
.chat-box .chat-box-header p {
  margin-bottom: 0;
}
.chat-box .chat-box-body {
  padding: 20px;
  height: calc(100vh - 240px);
  overflow-y: scroll;
}
@media (max-width: 991px) {
  .chat-box .chat-box-body {
    height: calc(100vh - 293px);
  }
}
.chat-box .chat-box-body .single-message .message-detail {
  font-size: 10px;
  line-height: 14px;
  font-family: "HKGrotesk-Bold";
  margin-bottom: 5px;
}
.chat-box .chat-box-body .single-message .message-bubble {
  padding: 15px;
  border-radius: 6px;
  font-size: 13px;
  line-height: 17px;
  width: 240px;
  max-width: fit-content;
}
.chat-box .chat-box-body .single-message.sent .message-detail {
  text-align: right;
}
.chat-box .chat-box-body .single-message.sent .message-bubble {
  background: rgba(255, 70, 40, 0.1);
  margin-left: auto;
}
.chat-box .chat-box-body .single-message.received .message-bubble {
  background: rgba(60, 60, 60, 0.1);
}
.chat-box .chat-box-body .single-message + .single-message {
  margin-top: 12px;
}
.chat-box .chat-box-footer {
  background: #f0f0f0;
  padding: 10px 20px;
  position: fixed;
  width: 330px;
  right: 0;
  bottom: 0;
}
.chat-box .chat-box-footer h6 {
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 5px;
}
.chat-box .chat-box-footer .message-send {
  display: flex;
  flex-wrap: nowrap;
}
.chat-box .chat-box-footer .message-send .form-control {
  height: 76px;
  width: 100%;
  border-radius: 5px;
  font-size: 13px;
  line-height: 17px;
}
.chat-box .chat-box-footer .message-send .form-control:focus {
  box-shadow: none;
  outline: none;
  border-color: transparent;
}
.chat-box .chat-box-footer .message-send .button-block {
  border-radius: 5px;
  width: 24px;
  min-width: 24px;
  height: 76px;
  background: $red;
  position: relative;
  margin-left: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-box .chat-box-footer .message-send .button-block button {
  border: none;
  width: 76px;
  min-width: 76px;
  height: 24px;
  line-height: normal;
  background: transparent;
  color: $white;
  font-size: 10px;
  font-family: "HKGrotesk-Bold";
  transform: rotate(-90deg);
}
.chat-box .chat-box-footer .message-send .button-block button:focus {
  box-shadow: none;
  outline: none;
}

.chat-box, .chat-box .chat-box-footer {
  max-width: 450px;
  width: 100%;
}